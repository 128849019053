body {
  background-color: #353535;
  color: #d9d9d9;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.button {
  color: #3C6E71;
  font-size: 2em;
  border: 1px solid #3C6E71;
  width: 64px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button.disabled {
  color: #555555;
  border-color: #555555;
  cursor: not-allowed;
}

.fixed-left-center {
  position: fixed;
  left: 0;
  top: 50vh;
  z-index: 50;
}
.fixed-left-bottom {
  position: fixed;
  left: 0;
  bottom: 4vh;
  z-index: 50;
}

.button.fixed-left-center,
.button.fixed-left-bottom {
  border-radius: 0 35px 35px 0;
  border-left-color: transparent;
}

.fixed-right-center {
  position: fixed;
  right: 0;
  top: 50vh;
  z-index: 50;
}
.fixed-right-bottom {
  position: fixed;
  right: 0;
  bottom: 4vh;
  z-index: 50;
}

.button.fixed-right-center,
.button.fixed-right-bottom {
  border-radius: 35px 0 0 35px;
  border-right-color: transparent;
}

.carousel-wrapper {
  white-space: nowrap;
  transition: ease 500ms;
}
.carousel {
  overflow-x: hidden;
  height: 100vh;
}
.slide {
  display: inline-block;
  width: 100vw;
  min-height: 100%;
  white-space: normal;
}
